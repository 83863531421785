/* jshint browser: true */
"use strict";

function WhistleRouterClass($, settings){	 // run everywhere
	var me = this;
	var params = settings.params;
	
	me.auto = function(){
		function capitalize(str){
			return str.charAt(0).toUpperCase() + str.slice(1);
		}
		function routeToClass(route){
			var chunks = route.split('.');
			autoClass = "";
			$.each(chunks, function(i){
			   autoClass += capitalize($.trim(chunks[i]));
			});
			return autoClass;
		}
		var route = $('body').data('route');
		autoClass = routeToClass(route);
		objectName = autoClass.toLowerCase();
		try{
			me[objectName] = new window[autoClass](me, $, settings);
		} catch(err) {
			// do nothing
		}
	}

	me.isFillOutSubmission = function(){
		return params.route == 'question' || params.route == 'filloutsubmission' ;
	}; 

	me.isListCases = function(){
		return params.route == 'listcases';
	};

	me.isEditSubmission = function(){
		return params.route == 'editsubmission';
	};

	me.isShowSubmission = function(){
		return params.route == 'showsubmission';
	};

	me.isEditWhistleblowerInfo = function(){
		return params.route == 'editwhistleblowerinfo';
	};

	me.isWhistleblowerLogin = function(){
		return params.route == 'whistleblowerlogin';
	};

	me.isWbProfile = function(){
		return params.route == 'wbprofile';
	};

	me.isUserLogin = function(){
		return params.route == 'userlogin';
	};

	me.isUserEdit = function(){
		return params.route == 'user.edit';
	};
	
	me.isInternalSubmission = function(){ 
		return !!$('#enterInternalSubmission').length;
	}

	me.changeParamsIfInternalSubmission = function( params ){
		if( me.isInternalSubmission() ){
			params.shiftPages = ['enterInternalSubmission'];
			params.excludePages = ['enterConfirmPrivacy', "enterAnonymousDecision"];
			// remove html to prevent trouble
			$(params.excludePages).each( function(){  $('#' + this).remove() } );
		}
		return params;
	}

	me.routes = function(){

		if( me.isFillOutSubmission() ){

			params = me.changeParamsIfInternalSubmission( params );
			
			me.fillOutSubmission = new FillOutSubmissionClass( me, $, settings );

		} else if( me.isListCases() ){

			me.isListCases = new ListCasesClass( me, $, settings );

		} else if( me.isEditSubmission() ){
			me.assignCase = new AssignCase( me, $, settings );
			me.logData = new LogData( me, $, settings );
			me.reports = new Reports( me, $, settings );
			me.editSubmission = new EditSubmission( me, $, settings );

		} else if( me.isShowSubmission() ){

			me.assignCase = new AssignCase( me, $, settings );
			me.logData = new LogData( me, $, settings );
			me.reports = new Reports( me, $, settings );
			me.showSubmission = new ShowSubmission( me, $, params );

		} else if( me.isEditWhistleblowerInfo() ){

			me.editWhistleblowerInfo = new EditWhistleblowerInfo( me, $, params );
			
		} else if( me.isWhistleblowerLogin() ){

			me.whistleblowerLogin = new WhistleblowerLogin( me, $, params );
		 	
		} else if( me.isUserLogin() ){

			me.userLogin = new UserLogin( me, $, params );
			
		} else if( me.isUserEdit() ){

			me.userEdit = new UserEdit( me, $, settings );
			
		}else if( me.isWbProfile() ){

			me.wbProfile = new WbProfile( me, $, settings );
			
		} else {
			me.auto();
		}
	};

	me.observe = function(){
		if( $('.uploadLink').length ){
			// me.uploadFiles = new UploadFilesClass( me, $, params )
			me.uploadFiles = new UploadFiles( me, $, params )
		}
		// $('.uploadLink').click( me.clickUploadLink );
	};

	me.ready = function(){
		me.submission = new Submission();
		me.report = new Report();

	};	

	me.observe();
	me.ready();
	me.routes();
}

/* onload run whistle */
$( function(){
 	var settings = new WhistleSettingsClass;
 	settings.strings = typeof whistleExportToJs == 'undefined' ? {} : whistleExportToJs;
	var modals = false;
	if( typeof bootbox == 'object' ){
		bootbox.setDefaults({ locale: settings.strings.locale, closeButton: false });
		modals = bootbox;
	}
	settings.params = { 
		page : $('body').data('page'),
		route : $('body').data('route'),
		modals : modals
	} ;

	if( typeof whistleRecaptchaObserve == 'function' ){
		settings.params.whistleRecaptchaObserve = whistleRecaptchaObserve;
	}

	// debugger
	window.whistle = window.whistle || {};
	window.whistle.router = new WhistleRouterClass($, settings);

    typeof $().tooltip == 'function' ? $('[data-toggle="tooltip"]').tooltip() : false;  	
}

);


